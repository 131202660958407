import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ChangeWorkLogModel, CreateWorkLogModel, ManufacturingOperationDetailModel } from '@api/models/timesheets';
import { ApiServiceName, TimesheetsController } from '@api/enums/controllers';
import { ApiService } from '@api/services/api.service';
import { BaseApiService } from '@api/services/base-api.service';

@Injectable({
  providedIn: 'root',
})
export class ManufacturingOperationDataService extends BaseApiService<
  ApiServiceName.Timesheets,
  TimesheetsController.ManufacturingOperationController
> {
  constructor(
    override readonly _apiService: ApiService,
    private readonly _httpClient: HttpClient,
  ) {
    super(_apiService, ApiServiceName.Timesheets, TimesheetsController.ManufacturingOperationController);
  }

  public getOperationByTaskNumber(taskNumber: string): Observable<ManufacturingOperationDetailModel> {
    return this._httpClient.get<ManufacturingOperationDetailModel>(`${this.baseUrl}/task-number=${taskNumber}`);
  }

  public createWorkLog(manufacturingOperationId: string, model: CreateWorkLogModel): Observable<void> {
    return this._httpClient.post<void>(`${this.baseUrl}/${manufacturingOperationId}/worklog`, model);
  }

  // currently not used, but still implemented in the API (may be used in the future)
  public changeWorkLog(manufacturingOperationId: string, workLogId: number, model: ChangeWorkLogModel): Observable<void> {
    return this._httpClient.put<void>(`${this.baseUrl}/${manufacturingOperationId}/worklog/${workLogId}`, model);
  }

  public cancelWorkLog(manufacturingOperationId: string, workLogId: number): Observable<void> {
    return this._httpClient.delete<void>(`${this.baseUrl}/${manufacturingOperationId}/worklog/${workLogId}`);
  }
}
